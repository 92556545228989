var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "p-link-line p-link-line--error c-section" },
    [
      _c("h1", { staticClass: "c-title2 c-title2--mb16" }, [
        _vm._v(
          " " +
            _vm._s(_vm.WORDS.OFFICIAL_LINE) +
            "の" +
            _vm._s(_vm.WORDS.LINE_ADD_FRIEND) +
            "が必要です "
        ),
      ]),
      _c("p", [
        _vm._v(
          " 下記の手順で、" +
            _vm._s(_vm.WORDS.OFFICIAL_LINE) +
            "の" +
            _vm._s(_vm.WORDS.LINE_ADD_FRIEND) +
            "を行ってください。 "
        ),
      ]),
      _c("ul", { staticClass: "p-link-line__follow-flow" }, [
        _c("li", [
          _vm._v(
            " ①「" +
              _vm._s(_vm.WORDS.LINE_ADD_FRIEND) +
              "する」ボタンをタップし、先の画面から" +
              _vm._s(_vm.WORDS.LINE_ADD_FRIEND) +
              "を行ってください。 "
          ),
        ]),
        _c("li", [
          _vm._v(
            " ②「" +
              _vm._s(_vm.WORDS.BACK_SERVICE) +
              "」ボタンをタップし、" +
              _vm._s(_vm.WORDS.APP_NAME) +
              "のサービスをお楽しみください。 "
          ),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "p-link-line__actions" },
        [
          _c(
            "ButtonLineLogin",
            { attrs: { href: _vm.lineFollowUrl, fluid: "" } },
            [_vm._v(" " + _vm._s(_vm.WORDS.LINE_ADD_FRIEND) + "する ")]
          ),
          _c(
            "Button",
            {
              attrs: {
                href: _vm.$customUrlScheme.page("root/home/1"),
                "style-type": "tertiary",
                fluid: "",
                flat: "",
              },
            },
            [_vm._v(" " + _vm._s(_vm.WORDS.BACK_SERVICE) + " ")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }