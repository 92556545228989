<template>
  <div class="p-link-line p-link-line--error c-section">
    <h1 class="c-title2 c-title2--mb16">
      {{ WORDS.OFFICIAL_LINE }}の{{ WORDS.LINE_ADD_FRIEND }}が必要です
    </h1>
    <p>
      下記の手順で、{{ WORDS.OFFICIAL_LINE }}の{{
        WORDS.LINE_ADD_FRIEND
      }}を行ってください。
    </p>
    <ul class="p-link-line__follow-flow">
      <li>
        ①「{{ WORDS.LINE_ADD_FRIEND }}する」ボタンをタップし、先の画面から{{
          WORDS.LINE_ADD_FRIEND
        }}を行ってください。
      </li>
      <li>
        ②「{{ WORDS.BACK_SERVICE }}」ボタンをタップし、{{
          WORDS.APP_NAME
        }}のサービスをお楽しみください。
      </li>
    </ul>
    <div class="p-link-line__actions">
      <ButtonLineLogin :href="lineFollowUrl" fluid>
        {{ WORDS.LINE_ADD_FRIEND }}する
      </ButtonLineLogin>
      <Button
        :href="$customUrlScheme.page('root/home/1')"
        style-type="tertiary"
        fluid
        flat>
        {{ WORDS.BACK_SERVICE }}
      </Button>
    </div>
  </div>
</template>

<script>
import { WORDS } from '@/constants/words';
import { defineComponent, computed } from '@vue/composition-api';

export default defineComponent({
  setup: () => {
    const lineFollowUrl = computed(
      () => `https://line.me/R/ti/p/${process.env.VUE_APP_LINE_BOT_BASIC_ID}`
    );
    return {
      WORDS,
      lineFollowUrl
    };
  }
});
</script>
